import { useState } from 'react'
import { ChatService } from '../services/chat.service'
import type { Chat, Message } from '../types/chat.types'

export function useChat() {
  const [chatId, setChatId] = useState<number | null>(null)
  const [chats, setChats] = useState<Chat[]>([])
  const [messages, setMessages] = useState<Message[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const sendMessage = async (input: string, evaluationId: number) => {
    const newMessage = { role: 'user', content: input }
    setMessages(prev => [...prev, newMessage])
    setIsLoading(true)

    try {
      const response = await ChatService.sendMessage(input, chatId, evaluationId)
      if (!chatId) {
        setChats(prev => [response.chat, ...prev])
      }
      setChatId(response.chat.id)
      setMessages(prev => [...prev, response.message])
      return response
    } finally {
      setIsLoading(false)
    }
  }

  const openChat = async (id: number) => {
    setChatId(id)
    const data = await ChatService.fetchChatHistory(id)
    setMessages(data)
    return data
  }

  const newChat = () => {
    setChatId(null)
    setMessages([])
  }

  return {
    chatId,
    chats,
    messages,
    isLoading,
    sendMessage,
    openChat,
    newChat,
    setChats
  }
}