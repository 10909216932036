import React from 'react'
import ReactMarkdown from 'react-markdown'
import { motion } from 'motion/react'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

function ChatBubbleContent({ isUser, children }: { isUser: boolean, children: React.ReactNode }) {
  return (
    <div
      className={`
        relative prose leading-relaxed
        text-base 
        ${isUser ? 'bg-gray-100 dark:bg-violet-900' : 'bg-transparent dark:bg-transparent'}
        p-4 
        ${isUser ? 'relative max-w-[var(--user-chat-width,70%)] rounded-3xl bg-token-message-surface px-5 py-2.5' : ''}
      `}
    >
      <span className={'text-black dark:text-white'}>
        {children}
      </span>
    </div>
  )
}

function ChatBubbleContainer({ isUser, children }: { isUser: boolean, children: React.ReactNode }) {
  return (
    <motion.div initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeOut' }} className={`${isUser ? 'col-start-6 col-end-13' : 'col-start-1 col-end-8'} p-2 rounded-lg`}>
      <div className={`flex ${isUser ? 'flex-row-reverse' : 'flex-row'} items-center`}>
        {children}
      </div>
    </motion.div>
  )
}

const MarkdownLink = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a
    {...props}
    target="_blank"
    rel="noopener noreferrer"
    className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 underline"
  />
)

const MarkdownTable = (props: React.TableHTMLAttributes<HTMLTableElement>) => (
  <table
    {...props}
    className="min-w-full border border-gray-300 rounded-lg shadow-md mt-5 mb-5"
  />
)

const MarkdownTableHead = (props: React.TableHTMLAttributes<HTMLTableSectionElement>) => (
  <thead
    {...props}
    className="bg-gray-200"
  />
)

const MarkdownTh = (props: React.ThHTMLAttributes<HTMLTableHeaderCellElement>) => (
  <th
    {...props}
    className="border border-gray-300 px-4 py-2 text-center"
  />
)

const MarkdownTd = (props: React.TdHTMLAttributes<HTMLTableDataCellElement>) => (
  <td
    {...props}
    className="border border-gray-300 px-4 py-2 text-center"
  />
)

const MarkdownOl = (props: React.OlHTMLAttributes<HTMLOListElement>) => (
  <ol
    {...props}
    className="list-decimal mt-2 mb-2"
  />
)

const MarkdownUl = (props: React.HTMLAttributes<HTMLUListElement>) => (
  <ul
    {...props}
    className="list-disc mt-2 mb-2"
  />
)

const MarkdownH2 = (props: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h2
    {...props}
    className="text-2xl mt-6 mb-6 font-semibold"
  />
)

const MarkdownH3 = (props: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h3
    {...props}
    className="text-xl mt-4 mb-4 font-medium"
  />
)

const MarkdownP = (props: React.HTMLAttributes<HTMLParagraphElement>) => (
  <p
    {...props}
    className="mt-5 mb-5"
  />
)

export const ChatBubble = ({ role, content }: { role: string, content: string }) => {
  const isUser = role === 'user'
  const cleanContent = content.replace(/\\\((.*?)\\\)/g, (_, match) => `$${match}$`)

  console.log('in chat bubble', content)
  return (
    <ChatBubbleContainer isUser={isUser}>
      <ChatBubbleContent isUser={isUser}>
        {
          isUser ? content : <ReactMarkdown
            components={{
              a: MarkdownLink,
              table: MarkdownTable,
              thead: MarkdownTableHead,
              th: MarkdownTh,
              td: MarkdownTd,
              ol: MarkdownOl,
              ul: MarkdownUl,
              h2: MarkdownH2,
              h3: MarkdownH3,
              p: MarkdownP
            }}
            remarkPlugins={[remarkMath, remarkGfm]}
            rehypePlugins={[[rehypeKatex, { strict: true }], rehypeRaw]}>
            {cleanContent}
          </ReactMarkdown>
        }

      </ChatBubbleContent>
    </ChatBubbleContainer>
  )
}
