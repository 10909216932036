export function NavItem({ children, active, onClick }: { children: React.ReactNode, active: boolean, onClick: () => void }) {
  return (
    <li>
      <div
        className={`flex items-center cursor-pointer px-3 py-2 text-base font-medium text-gray-900 rounded-lg hover:scale-[1.02] hover:bg-violet-100 dark:hover:bg-violet-900 dark:text-white group ${active ? 'bg-violet-200 dark:bg-violet-800' : ''}`}
        onClick={onClick}
      >
        {children}
      </div>
    </li>
  )
}