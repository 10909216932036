

import config from '../config/index'
import { AuthenticationService } from '../services/auth.service'
import { ApiUtils } from './api.utils'


interface QueryParamsType {
  path: string
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
}
const query = async ({ path, options }: QueryParamsType) => {

  const headers = { ...options.headers, 'Authorization': `Bearer ${AuthenticationService.getToken()}` }
  const response = await ApiUtils.retryWithExponentialBackoff(async () => await fetch(`${config.api_url}/${path}`, { ...options, headers }))
  return await response.json()
}


export const Rest = {
  query,
}
