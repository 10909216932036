
import { InternalServerError } from '@ed/types'
import config from '../config/index'
import { AuthenticationService } from '../services/auth.service'
import { ApiUtils } from './api.utils'

interface GraphqlParamsType {
  query: string
  variables: {
    [key: string]: unknown
  }
}
const query = async ({ query, variables }: GraphqlParamsType) => {
  console.log({
    query,
    variables
  })
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${AuthenticationService.getToken()}`
    },
    body: JSON.stringify({
      query: query,
      variables: variables
    })
  }

  try {
    const response = await ApiUtils.retryWithExponentialBackoff(async () => await fetch(`${config.graphql_url}`, options))
    if (!response.ok) {
      throw new InternalServerError(`HTTP error! status: ${response.status}`)
    }

    const responseBody = await response.json()
    if (responseBody.errors) {
      throw new InternalServerError(`GraphQL error: ${JSON.stringify(responseBody.errors)}`)
    }

    return responseBody.data
  } catch (error) {
    // @ts-ignore
    console.error('Error fetching GraphQL data:', error.message)
  }
}

export const GraphQL = {
  query
}
