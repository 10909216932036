const retryWithExponentialBackoff = async <T>(
  fn: () => Promise<T>,
  maxRetries: number = 4,
  initialDelay: number = 1000,
  maxDelay: number = 30000
): Promise<T> => {
  let retries = 0
  let delay = initialDelay


  while (true) {
    try {
      return await fn()
    } catch (error) {
      console.log(error)
      if (retries >= maxRetries) {
        throw error
      }
      retries++
      console.warn(`Retry attempt ${retries} failed. Retrying in ${delay}ms.`)
      await new Promise(resolve => setTimeout(resolve, delay))
      delay = Math.min(delay * 2, maxDelay)
    }
  }
}

export const ApiUtils = {
  retryWithExponentialBackoff
}
