import { GraphQL } from '../utils/graphql.utils'
import { Rest } from '../utils/rest.utils'
import { AuthenticationService } from './auth.service'
import type { Chat } from '../types/chat.types'


const fetchChats = async () => {
  const user = await AuthenticationService.getUser()

  const query = `
    query ($id: Int!) {
      client {
        conversations(userIds: [$id]) {
          total
          hits {
            id
            createdAt
            updatedAt
            messages
            user {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
 `

  const data = await GraphQL.query({
    query: query,
    variables: {
      id: parseInt(user.id)
    }
  })

  const history = data.client.conversations.hits.map((chat: Chat) => ({
    id: chat.id,
    title: chat?.messages?.[0]?.content?.substring(0, 30),
    updatedAt: chat.updatedAt
  }))

  return history
}

const fetchChatHistory = async (chatId: number) => {
  const query = `
    query ($chatId: Int!) {
      client {
        conversations(ids: [$chatId]) {
          total
          hits {
            id
            messages
          }
        }
      }
    }
  `
  const data = await GraphQL.query({
    query: query,
    variables: {
      chatId: chatId
    }
  })

  return data.client.conversations.hits[0].messages
}

const sendMessage = async (message: string, conversationId: number | null, evaluationId: number) => {
  try {
    const data = await Rest.query(
      {
        path: 'chat/remediation',
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            message,
            evaluationId,
            ...(conversationId !== null && { conversationId }),
          })
        }
      }
    )

    return {
      chat: {
        id: data.conversationId,
        updatedAt: new Date().toISOString(),
        title: message
      },
      message: {
        role: 'assistant',
        content: data.message.content,
        agent: data.message.agent
      }
    }
  } catch (error) {
    console.error('Error', error)
    throw error
  }
}


export const ChatService = {
  fetchChats,
  fetchChatHistory,
  sendMessage
}