import { NavItem } from '../navigation/navi.item.component'
import type { Chat } from '../../types/chat.types'

export function ChatGroup({ title, chats, activeChatId, onChatClick }: { title: string, chats: Chat[], activeChatId: number, onChatClick: (id: number) => void }) {
  if (chats.length === 0) return null

  return (
    <div className="mb-4">
      <h3 className="px-3 py-2 text-sm font-bold text-[#604AFF] dark:text-violet-400">
        {title}
      </h3>
      <ul className="space-y-2">
        {chats.map((chat) => (
          <NavItem
            key={chat.id}
            active={activeChatId === chat.id}
            onClick={() => onChatClick(chat.id)}
          >
            <span className="truncate text-ellipsis font-normal">
              {chat.title}
            </span>
          </NavItem>
        ))}
      </ul>
    </div>
  )
}