import { LoadingDots } from '../loading.dot.component'
import { ChatBubble } from './chat.buble.component'
import type { Message } from '../../types/chat.types'

export function ChatMessages({ messages, isLoading }: { messages: Message[], isLoading: boolean }) {
  return (
    <div className="flex flex-col space-y-4">
      {messages.map((message, index) => (
        <ChatBubble
          key={index}
          role={message.role}
          content={message.content}
        />
      ))}
      {isLoading && (
        <div className="flex justify-start">
          <div className="bg-gray-200 dark:bg-gray-700 rounded-lg px-4 py-2">
            <LoadingDots />
          </div>
        </div>
      )}
    </div>
  )
}