import type { Chat } from '../types/chat.types'

export const groupChatsByDate = (chats: Chat[]) => {
  const today = new Date()
  const yesterday = new Date(today)
  const threeDaysAgo = new Date(today)
  const sevenDaysAgo = new Date(today)

  yesterday.setDate(yesterday.getDate() - 1)
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3)
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

  return {
    today: chats.filter(chat => {
      const chatDate = new Date(chat.updatedAt)
      return chatDate.toDateString() === today.toDateString()
    }),
    yesterday: chats.filter(chat => {
      const chatDate = new Date(chat.updatedAt)
      return chatDate.toDateString() === yesterday.toDateString()
    }),
    threeDays: chats.filter(chat => {
      const chatDate = new Date(chat.updatedAt)
      return chatDate > threeDaysAgo &&
        chatDate.toDateString() !== today.toDateString() &&
        chatDate.toDateString() !== yesterday.toDateString()
    }),
    week: chats.filter(chat => {
      const chatDate = new Date(chat.updatedAt)
      return chatDate > sevenDaysAgo &&
        chatDate <= threeDaysAgo
    }),
    older: chats.filter(chat => {
      const chatDate = new Date(chat.updatedAt)
      return chatDate <= sevenDaysAgo
    })
  }
}