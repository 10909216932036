import React, { useEffect } from 'react'
import { AuthenticationService } from '../../services/auth.service'
import config from '../../config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withAuth = (WrappedComponent: React.ComponentType<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function WithAuthComponent(props: any) {
    useEffect(() => {
      const checkAuth = () => {
        const token = AuthenticationService.getToken()
        if (!token) {
          const origin = encodeURIComponent(window.location.href)
          window.location.href = `${config.sso_url}?origin=${origin}`
        }
      }

      checkAuth()
    }, [])

    const token = AuthenticationService.getToken()
    if (!token) {
      return null
    }

    return <WrappedComponent {...props} />
  }
}