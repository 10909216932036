import { Icon } from '@ed/ui'
import type {
  KeyboardEvent,
  ChangeEvent } from 'react'
import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react'

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  isLoading: boolean;
}

export const ChatInput = forwardRef<HTMLTextAreaElement, ChatInputProps>(
  ({ onSendMessage, isLoading }, ref) => {
    const [input, setInput] = useState('')
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    // Permet à l'extérieur (parent) d'accéder à la référence du textarea si nécessaire
    useImperativeHandle(ref, () => textareaRef.current as HTMLTextAreaElement)

    // Ajuste dynamiquement la hauteur du textarea à chaque changement de contenu
    useEffect(() => {
      if (textareaRef.current) {
        // Réinitialise la hauteur pour recalculer correctement le scrollHeight
        textareaRef.current.style.height = 'auto'
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
      }
    }, [input])

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setInput(e.target.value)
    }

    const handleSendMessage = () => {
      if (input.trim() === '' || isLoading) return
      onSendMessage(input)
      setInput('')
    }

    const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      // Envoi le message si on presse "Enter" sans Shift
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        handleSendMessage()
      }
    }

    return (
      <div className="relative w-full">
        <textarea
          ref={textareaRef}
          value={input}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          placeholder="Tapez votre message..."
          disabled={isLoading}
          rows={1}
          className={`flex w-full cursor-text flex-col rounded-3xl border border-token-border-light px-3 py-3 duration-150 ease-in-out contain-inline-size
            motion-safe:transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow,bottom]
            dark:border-none dark:shadow-none focus:shadow-[0_9px_9px_0px_rgba(0,0,0,0.01),_0_2px_5px_0px_rgba(0,0,0,0.06)] has-[:focus]:shadow-[0_2px_12px_0px_rgba(0,0,0,0.04),_0_9px_9px_0px_rgba(0,0,0,0.01),_0_2px_5px_0px_rgba(0,0,0,0.06)]
             dark:bg-[#303030] focus:outline-none resize-none
            ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
                    `}
          style={{ minHeight: '80px', maxHeight: '200px' }}
        />
        {input.trim().length > 0 && !isLoading && (
          <button
            onClick={handleSendMessage}
            className="absolute right-2 top-1/2 -translate-y-1/2 transform
                       rounded-full bg-[#604AFF] px-1 py-1 text-white
                       hover:bg-[#604AFF] transition"
          >
            <Icon.Send/>
          </button>
        )}
      </div>
    )
  }
)

ChatInput.displayName = 'ChatInput'
