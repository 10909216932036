import { groupChatsByDate } from '../../utils/date.utils'
import { ChatGroup } from '../chat/chat.group.component'
import type { Chat } from '../../types/chat.types'


export function Sidebar({
  drawerOpen,
  chats,
  chatId,
  onChatClick
}: {
  drawerOpen: boolean,
  chats: Chat[],
  chatId: number,
  onChatClick: (id: number) => void
}) {
  const chatGroups = groupChatsByDate(chats)

  return (
    <aside
      className={
        `fixed top-0 left-0 z-40 w-80 h-screen transition-transform ${drawerOpen ? 'translate-x-0' : '-translate-x-full'
        } bg-gray-100 border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
    >
      <div className="overflow-y-auto py-16 px-3 h-full bg-gray-100 dark:bg-gray-800">
        {chats.length > 0 && (
          <div className="space-y-4">
            <ChatGroup
              title="Aujourd'hui"
              chats={chatGroups.today}
              activeChatId={chatId}
              onChatClick={onChatClick}
            />
            <ChatGroup
              title="Hier"
              chats={chatGroups.yesterday}
              activeChatId={chatId}
              onChatClick={onChatClick}
            />
            <ChatGroup
              title="3 derniers jours"
              chats={chatGroups.threeDays}
              activeChatId={chatId}
              onChatClick={onChatClick}
            />
            <ChatGroup
              title="Cette semaine"
              chats={chatGroups.week}
              activeChatId={chatId}
              onChatClick={onChatClick}
            />
            <ChatGroup
              title="Plus ancien"
              chats={chatGroups.older}
              activeChatId={chatId}
              onChatClick={onChatClick}
            />
          </div>
        )}
      </div>
    </aside>
  )
}
