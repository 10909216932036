import { Env, Environment } from '@ed/ui'

type Config = {
  api_url: string
  graphql_url: string
  sso_url: string
  front_url: string
}

const config_local: Config = {
  api_url: 'http://localhost:4000',
  graphql_url: 'http://localhost:4000/graphql',
  sso_url: 'http://localhost:3001/fr/login',
  front_url: 'http://localhost:3004'
}

const config_staging: Config = {
  api_url: 'https://api.staging.ed.ai',
  graphql_url: 'https://api.staging.ed.ai/graphql',
  sso_url: 'https://sso.staging.ed.ai/fr/login',
  front_url: 'https://app.staging.chat.ed.ai'
}

const config_preview: Config = config_staging

const config_production: Config = {
  api_url: 'https://api.ed.ai',
  graphql_url: 'https://api.ed.ai/graphql',
  sso_url: 'https://sso.ed.ai/fr/login',
  front_url: 'https://app.chat.ed.ai'
}

const config = (env: string): Config => {
  switch (true) {
    case env === Environment.Local:
      return config_local
    case env === Environment.Preview:
      return config_staging
    case env === Environment.Staging:
      return config_preview
    case env === Environment.Production:
      return config_production
    default:
      return config_local
  }
}

export default config(Env.CONF_ENV)



