import { useState, useEffect, useRef } from 'react'
import { ChatService } from './services/chat.service'
import { Sidebar } from './components/sidebar/sidebar.component'
import { ChatMessages } from './components/chat/chat.messages.component'
import { ChatInput } from './components/chat/chat.input.component'
import { useChat } from './hooks/use.chat.hook'
import { withAuth } from './components/auth/auth.component'

// For now seems overkill to use react-router
// Assume we are on URL https://{bot.ed.ai}/evaluation/:id
const getEvaluationId = (): number => {
  const pathSegments = window.location.pathname.split('/')
  return parseInt(pathSegments[pathSegments.length - 1], 10)
}


interface UiState {
  drawerOpen: boolean;
  darkMode: boolean;
}

interface Refs {
  messagesEnd: React.RefObject<HTMLDivElement>;
  inputField: React.RefObject<HTMLTextAreaElement>;
}

const AppComponent = () => {
  const evaluationId = getEvaluationId()
  const [uiState, setUiState] = useState<UiState>({
    drawerOpen: true,
    darkMode: false
  })

  const refs: Refs = {
    messagesEnd: useRef<HTMLDivElement>(null),
    inputField: useRef<HTMLTextAreaElement>(null)
  }

  const {
    chatId,
    chats,
    messages,
    isLoading,
    sendMessage,
    openChat,
    newChat,
    setChats
  } = useChat()

  useEffect(() => {
    document.documentElement.classList.toggle('dark', uiState.darkMode)
  }, [uiState.darkMode])

  useEffect(() => {
    refs?.inputField?.current?.focus()
    ChatService.fetchChats()
      .then(setChats)
      .catch(error => console.error('Error fetching chats:', error))
  }, [refs?.inputField, setChats])

  const scrollToBottom = () => {
    setTimeout(() => {
      refs.messagesEnd.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  const handleSendMessage = async (input: string) => {
    await sendMessage(input, evaluationId)
    scrollToBottom()
  }

  const handleOpenChat = async (id: number) => {
    await openChat(id)
    scrollToBottom()
    refs.inputField.current?.focus()
  }

  const handleNewChat = () => {
    newChat()
    refs?.inputField?.current?.focus()
  }

  return (
    <div className="antialiased bg-white dark:bg-gray-900">

      <button
        className="fixed top-4 left-4 z-50 p-2 rounded-lg hover:bg-violet-200 dark:hover:bg-violet-800"
        onClick={() => setUiState(prev => ({ ...prev, drawerOpen: !prev.drawerOpen }))}
      >
        <svg className="w-6 h-6 text-[#604AFF] dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>

      <button
        className="fixed top-4 left-16 z-50 p-2 rounded-lg hover:bg-violet-200 dark:hover:bg-violet-800"
        onClick={handleNewChat}
      >
        <svg className="w-6 h-6 text-[#604AFF] dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
        </svg>
      </button>

      <Sidebar
        drawerOpen={uiState.drawerOpen}
        chats={chats}
        chatId={chatId ?? 0}
        onChatClick={handleOpenChat}
      />

      <main className="p-4 md:ml-64 pt-20 dark:text-white h-screen bg-white dark:bg-gray-900">
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl h-full py-2 px-4 bg-white dark:bg-gray-900">
          <div className="h-full overflow-x-auto mb-6 flex flex-col">
            <div className="max-w-3xl mx-auto w-full">
              <ChatMessages messages={messages} isLoading={isLoading} />
              <div ref={refs.messagesEnd} />
            </div>
          </div>
          <div className="max-w-3xl mx-auto w-full">
            <ChatInput onSendMessage={handleSendMessage} ref={refs.inputField} isLoading={isLoading} />
          </div>
        </div>
      </main>
    </div>
  )
}


export const App = withAuth(AppComponent)
